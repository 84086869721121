import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import "firebase/compat/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6YatVbUCoiW0oMk4Z84SM0LF0X8u2KM0",
  authDomain: "ian-and-hannah-cartagena-2023.firebaseapp.com",
  projectId: "ian-and-hannah-cartagena-2023",
  storageBucket: "ian-and-hannah-cartagena-2023.appspot.com",
  messagingSenderId: "811330709304",
  appId: "1:811330709304:web:bc07d22928372bf67903cd",
  measurementId: "G-Z6C7HTD293",
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

const USE_EMULATOR = false;

if (process.env.NODE_ENV === "development" && USE_EMULATOR) {
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
}
