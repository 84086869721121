import React, { Suspense, lazy, useCallback, useEffect } from "react";

import { css } from "@linaria/core";
import Dialog from "@mui/material/Dialog";
import { setUserId } from "firebase/analytics";
import { Close } from "grommet-icons";
import { useMediaQuery } from "hooks/useMediaQuery";
import { ResetPassword } from "pages/Login/ResetPassword";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { MOBILE_QUERY } from "theme";

import { SlideUpTransition } from "./components/SlideUpTransition";
import { Theme } from "./components/Theme";
import { analytics, firebaseAuth } from "./firebaseInit";
import { Nav } from "./Nav";
import { Login, LoginForm } from "./pages/Login/Login";

import "destyle.css";
import "react-image-lightbox/style.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";

const Home = lazy(() => import(/* webpackChunkName: "home" */ "./pages/Home/Home"));

const Events = lazy(() => import(/* webpackChunkName: "events" */ "./pages/Events/Events"));

const DressCode = lazy(
  () => import(/* webpackChunkName: "dress-code" */ "./pages/DressCode/DressCode")
);

const Cartagena = lazy(() => import(/* webpackChunkName: "info" */ "./pages/Cartagena/Cartagena"));
const Entourage = lazy(() => import(/* webpackChunkName: "entourage" */ "./pages/Entourage"));

const HotelSantaTeresa = lazy(
  () => import(/* webpackChunkName: "hotel-santa-teresa" */ "pages/Cartagena/HotelSantaTeresa")
);

const MovichHotel = lazy(
  () => import(/* webpackChunkName: "movich-hotel" */ "pages/Cartagena/MovichHotel")
);

const closeButton = css`
  position: absolute;
  right: 30px;
  top: 20px;
`;

function App() {
  const [user, loadingAuth] = useAuthState(firebaseAuth);

  const navigate = useNavigate();
  const location = useLocation();
  const { backgroundLocation, from } = (location.state || {}) as {
    backgroundLocation?: Location;
    from?: string;
  };

  useEffect(() => {
    if (user) {
      setUserId(analytics, user.uid);
      if (from) {
        navigate(from, { replace: true });
      } else if (location.pathname === "/login") {
        navigate("/", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, from]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const isMobile = useMediaQuery(MOBILE_QUERY);

  if (loadingAuth) {
    // TODO: add loading state
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Theme>
        <Routes location={backgroundLocation || location}>
          <Route
            path="/"
            element={
              user ? (
                <>
                  <Home />
                  <Nav />
                </>
              ) : (
                <Home />
              )
            }
          />
          <Route path="/login" element={<Login onDismiss={goHome} />} />
          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route element={<AuthedApp />}>
            <Route path="/events" element={<Events />} />
            <Route path="/dress-code" element={<DressCode />} />
            <Route path="/info/hotel-santa-teresa" element={<HotelSantaTeresa />} />
            <Route path="/info/movich-hotel" element={<MovichHotel />} />
            <Route path="/info/*" element={<Cartagena />} />
            <Route path="/entourage" element={<Entourage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {backgroundLocation && !user && (
          <Routes>
            <Route
              path="/login"
              element={
                <Dialog
                  open
                  onClose={goBack}
                  TransitionComponent={SlideUpTransition}
                  fullScreen={isMobile}
                >
                  <LoginForm />
                  <button onClick={goHome} className={closeButton}>
                    <Close color="icon-neutral" />
                  </button>
                </Dialog>
              }
            />
          </Routes>
        )}
      </Theme>
    </Suspense>
  );
}

function AuthedApp() {
  const [user] = useAuthState(firebaseAuth);
  const location = useLocation();

  return user ? (
    <>
      <Nav />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} replace />
  );
}

export default App;
