import React from "react";

import { cx } from "@linaria/core";
import { Grommet, GrommetExtendedProps } from "grommet";

import { darkTheme, lightTheme } from "../theme";

export const Theme = ({ className, themeMode, ...props }: GrommetExtendedProps) => {
  const isDarkTheme = themeMode === "dark";

  return (
    <Grommet
      {...props}
      themeMode={themeMode}
      theme={isDarkTheme ? darkTheme : lightTheme}
      cssVars
      className={cx(className, isDarkTheme ? "dark-theme" : "light-theme")}
    />
  );
};
