import React, { ChangeEventHandler, useCallback, useEffect, useState } from "react";

import { css } from "@linaria/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import stockImage from "assets/cartagena13.jpeg";
import { Button } from "components/Button";
import { AuthError } from "firebase/auth";
import { firebaseAuth } from "firebaseInit";
import { Box, ButtonProps, Heading, Text } from "grommet";
import { Close } from "grommet-icons";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { IS_MOBILE, MOBILE_GUTTER, MOBILE_QUERY } from "theme";

import { getErrorMessage } from "./utils";

const container = css`
  width: 400px;
  padding: 0 30px 30px;
  flex-shrink: 0;

  ${IS_MOBILE} {
    width: 100%;
    height: 100%;
    padding: 0 ${MOBILE_GUTTER}px 30px;
  }
`;

const errorText = css`
  height: 44px;
`;

const closeButton = css`
  position: absolute;
  right: ${MOBILE_GUTTER}px;
  top: 20px;

  ${IS_MOBILE} {
    top: 33px;
  }
`;

interface LoginProps {
  onDismiss: () => void;
}

export const LoginForm = () => {
  const location = useLocation();
  const { backgroundLocation } = (location.state || { location: "/" }) as {
    backgroundLocation: Location;
  };
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();

  const handleEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setEmail(event.target.value);
    setError(undefined);
  }, []);

  const handlePasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setPassword(event.target.value);
    setError(undefined);
  }, []);

  const handleToggleShowPassword = useCallback(() => {
    setShouldShowPassword((value) => !value);
  }, []);

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleForgotPassword = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const [signInWithEmailAndPassword, _, isSubmitting, signInError] =
    useSignInWithEmailAndPassword(firebaseAuth);

  const handleSubmit = useCallback(async () => {
    await signInWithEmailAndPassword(email, password);
  }, [email, password, signInWithEmailAndPassword]);

  useEffect(() => {
    if (signInError) {
      setError(signInError);
    }
  }, [signInError]);

  return (
    <Box className={container} gap="medium">
      <Heading level={2}>Sign in</Heading>
      <Box as="form" align={backgroundLocation ? undefined : "end"} gap="medium" width="100%">
        <TextField
          name="email"
          type="email"
          label="Email"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          required
          aria-required
        />
        <Box gap="small" align="end" width="100%">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="password-field" required>
              Password
            </InputLabel>
            <OutlinedInput
              id="password-field"
              name="password"
              type={shouldShowPassword ? "text" : "password"}
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              required
              aria-required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            label={
              <Text color="text-secondary" onClick={handleForgotPassword}>
                forgot password
              </Text>
            }
            plain
            size="small"
          />
        </Box>
        <Text color="status-error" className={errorText}>
          {error ? getErrorMessage(error) : ""}
        </Text>
        <Button
          fill
          type="submit"
          justify="center"
          size="medium"
          label="Sign in"
          primary
          onClick={handleSubmit}
          disabled={!email || !password || isSubmitting}
        />
      </Box>
    </Box>
  );
};

const image = css`
  height: 100vh;
  width: 60%;
  object-fit: cover;
  object-position: left center;

  ${IS_MOBILE} {
    height: auto;
    width: 100%;
  }
`;

export function Login({ onDismiss }: LoginProps) {
  // const [user] = useAuthState(firebaseAuth);
  const isMobile = useMediaQuery(MOBILE_QUERY);

  // if (user) {
  //   return <Navigate to="/" replace />;
  // }

  return (
    <Box direction={isMobile ? "column-reverse" : "row"}>
      <img src={stockImage} alt="" className={image} />
      <Box align="center" flex="grow" justify={isMobile ? undefined : "center"}>
        <LoginForm />
      </Box>
      <button onClick={onDismiss} className={closeButton}>
        <Close color="icon-neutral" />
      </button>
    </Box>
  );
}

export const LoginButton = ({
  className,
  backgroundLocation,
  ...props
}: { backgroundLocation?: string; className?: string } & ButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = useCallback(() => {
    navigate("/login", { state: { backgroundLocation: backgroundLocation ?? location } });
  }, [backgroundLocation, location, navigate]);

  return <Button onClick={handleClick} className={className} label="Sign in" {...props} />;
};
