import { ThemeType } from "grommet";
import { deepMerge } from "grommet/utils";

export const PARAGRAPH_MAX_WIDTH = "600px";

export const lightTheme: ThemeType = {
  button: {
    size: {
      small: {
        pad: {
          vertical: "4px",
          horizontal: "20px",
        },
      },
      medium: {
        pad: {
          vertical: "10px",
          horizontal: "24px",
        },
        border: {
          radius: "24px",
        },
      },
      large: {
        pad: {
          vertical: "12px",
          horizontal: "30px",
        },
        border: {
          radius: "30px",
        },
      },
    },
  },
  checkBox: {
    hover: {
      border: { color: "brand" },
    },
    gap: "medium",
    label: {
      align: "start",
    },
    size: "20px",
  },
  global: {
    font: {
      family:
        '"Maison Neue", -apple-system, BlinkMacSystemFont,  "Segoe UI",  Roboto,  Oxygen,  Ubuntu,  Cantarell,  "Fira Sans",  "Droid Sans",   "Helvetica Neue",  Arial, sans-serif,   "Apple Color Emoji",  "Segoe UI Emoji",  "Segoe UI Symbol"',
      size: "16px",
      height: "20px",
    },
    colors: {
      brand: "#FF7E0C",
      "brand-wash": "#FBE9D9",
      "accent-1": "#FCD0C8",
      "accent-2": "#25752A",
      "neutral-1": "#E19288",
      "neutral-2": "#235A30",
      complimentary: "#3FA5FF",
      "light-1": "#FAF5E4",
      focus: "#EAB371",
      text: "#000000",
      "text-secondary": "#646970",
      foreground: "#FFFFFF",
      "icon-neutral": "#000000",
      divider: "#dbdbdb",
      backdrop: "rgb(230, 230, 230, 0.6)",
    },
  },
  paragraph: {
    medium: {
      size: "16px",
      height: "24px",
      maxWidth: PARAGRAPH_MAX_WIDTH,
    },
  },
  text: {
    medium: {
      size: "16px",
      height: "22px",
    },
  },
};

export const darkTheme: ThemeType = deepMerge(lightTheme, {
  global: {
    colors: {
      text: "#F1F7ED",
      "text-secondary": "rgba(255, 255, 255, 0.8)",
      foreground: "#000000",
      "icon-neutral": "#FAF5E4",
      divider: "rgba(255, 255, 255, 0.2)",
    },
  },
});

export const customMuiTextField = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#dbdbdb" },
  },
};

export const DESKTOP = 769;
export const MOBILE_GUTTER = 20;
export const DESKTOP_HORIZONTAL_MARGIN = 60;

export const MOBILE_NAV_BAR_HEIGHT = 80;
export const DESKTOP_NAV_BAR_HEIGHT = 110;

export const DESKTOP_QUERY = `(min-width: ${DESKTOP}px)`;
export const MOBILE_QUERY = `(max-width: ${DESKTOP - 1}px)`;

export const IS_DESKTOP = `@media ${DESKTOP_QUERY}`;
export const IS_MOBILE = `@media ${MOBILE_QUERY}`;

export const darkRoutes = ["/"];
