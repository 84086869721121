import React from "react";

import { css, cx } from "@linaria/core";
import { firebaseAuth } from "firebaseInit";
import { Box, BoxProps, ButtonType, Button as GButton, Text } from "grommet";
import { Share } from "grommet-icons";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useAuthState } from "react-firebase-hooks/auth";
import { MOBILE_QUERY } from "theme";

const button = css`
  position: relative;
`;

const shimmer = css`
  &::after {
    position: absolute;
    top: -2px; // border width
    right: 0;
    bottom: -2px; // border width
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 234, 216, 0) 0,
      rgba(255, 234, 216, 0.1) 20%,
      rgba(255, 234, 216, 0.35) 60%,
      rgba(255, 234, 216, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

const fit = css`
  width: fit-content;
`;

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
};

export function Button({
  className,
  label,
  loading,
  fill,
  ...props
}: ButtonType & { loading?: boolean }) {
  let labelColor = "brand";
  if (props.primary) {
    labelColor = "foreground";
  } else if (props.plain) {
    labelColor = "text";
  }

  return (
    <GButton
      className={cx(className, button, loading && shimmer, !fill && fit)}
      style={buttonStyle}
      label={
        <Text size={props.size} weight={500} color={labelColor}>
          {label}
        </Text>
      }
      {...props}
    />
  );
}

export function RSVPButton({ align = "center" }: Pick<BoxProps, "align">) {
  const [user] = useAuthState(firebaseAuth);
  const isMobile = useMediaQuery(MOBILE_QUERY);

  if (!user) {
    return null;
  }

  return (
    <Box gap="small" align={align}>
      <Button
        as="a"
        primary
        label="RSVP"
        href="https://withjoy.com/jinandbarrett/rsvp"
        icon={<Share size="16x" color="foreground" />}
        reverse
        size={isMobile ? "medium" : "large"}
      />
      <Text color="text">
        <span className="mono">HIbarretts2023</span>
      </Text>
    </Box>
  );
}
