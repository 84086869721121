import React, { ChangeEventHandler, useCallback, useEffect, useState } from "react";

import { css } from "@linaria/core";
import TextField from "@mui/material/TextField";
import { Button } from "components/Button";
import { AuthError } from "firebase/auth";
import { firebaseAuth } from "firebaseInit";
import { Box, Heading, Paragraph, Text } from "grommet";
import { useAuthState, useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { IS_MOBILE, MOBILE_GUTTER } from "theme";

import { LoginButton } from "./Login";
import { getErrorMessage } from "./utils";

const container = css`
  width: 400px;
  padding: 10% 20px 30px;
  flex-shrink: 0;

  ${IS_MOBILE} {
    width: 100%;
    height: 100%;
    padding: 0 ${MOBILE_GUTTER}px 30px;
  }
`;

const errorText = css`
  height: 44px;
`;

const cta = css`
  width: 50%;
`;

export function ResetPassword() {
  const [user] = useAuthState(firebaseAuth);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<Error | AuthError | undefined>();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();
  const goToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setEmail(event.target.value);
    setIsSubmitted(false);
    setError(undefined);
  }, []);

  const [sendResetPasswordEmail, isSubmitting, resetPasswordError] =
    useSendPasswordResetEmail(firebaseAuth);
  const handleSubmit = useCallback(async () => {
    await sendResetPasswordEmail(email);
    setIsSubmitted(true);
  }, [email, sendResetPasswordEmail]);

  useEffect(() => {
    if (resetPasswordError) {
      setError(resetPasswordError);
    }
  }, [resetPasswordError]);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box width="100%" align="center">
      {isSubmitted && !error ? (
        <Box gap="medium" className={container}>
          <Heading level={2}>Check your email</Heading>
          <Paragraph>We have sent you an email with a link to reset your password.</Paragraph>
          <Box direction="row" gap="medium" align="center" width="100%">
            <LoginButton size="medium" backgroundLocation="/" secondary className={cta} fill />
            <Button
              fill
              justify="center"
              size="medium"
              label="Go home"
              primary
              onClick={goToHome}
              className={cta}
            />
          </Box>
        </Box>
      ) : (
        <Box as="form" gap="medium" className={container}>
          <Heading level={2}>Forgot your password?</Heading>
          <Paragraph>
            Resetting your password is quick and easy. Just enter the email for your registered
            guest and follow instructions in your inbox to reset your password.
          </Paragraph>
          <TextField
            name="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            required
          />
          <Text color="status-error" className={errorText}>
            {error ? getErrorMessage(error) : ""}
          </Text>
          <Box direction="row" align="center" gap="medium">
            <LoginButton className={cta} backgroundLocation="/" secondary size="medium" fill />
            <Button
              className={cta}
              type="submit"
              label="Reset password"
              size="medium"
              primary
              onClick={handleSubmit}
              disabled={!email || isSubmitting}
              fill
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
