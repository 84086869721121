import { FirebaseError } from "firebase/app";

export const getErrorMessage = (error: Error) => {
  if (error instanceof FirebaseError) {
    if (error.message.includes("auth/user-not-found")) {
      return "We cannot find the user associated with this email. Please contact hosts if this is a mistake.";
    }
    if (error.message.includes("auth/wrong-password")) {
      return "Incorrect credentials. Please try again.";
    }
  }

  return "Couldn’t complete the request. Please try again.";
};
