import React, { MouseEventHandler, memo, useCallback, useEffect, useRef, useState } from "react";

import { css, cx } from "@linaria/core";
import { Theme } from "components/Theme";
import { Transition, Variants, motion, useAnimation, useScroll, useTransform } from "framer-motion";
import { Heading, Layer, Text } from "grommet";
import { Close, Menu as HamburgerIcon, Logout } from "grommet-icons";
import { useMediaQuery } from "hooks/useMediaQuery";
import { LinkProps, NavLink, useLocation, useNavigate } from "react-router-dom";
import { titleCase } from "utils/titleCase";

import { firebaseAuth } from "./firebaseInit";
import {
  DESKTOP_HORIZONTAL_MARGIN,
  IS_DESKTOP,
  IS_MOBILE,
  MOBILE_GUTTER,
  MOBILE_QUERY,
  darkRoutes,
  lightTheme,
} from "./theme";

const navLinks = css`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  list-style: none;
  margin: 0;
  // offset the link item padding
  margin-left: -4px;
  padding: 0;

  ${IS_DESKTOP} {
    flex-direction: row;

    > li:not(:first-child) {
      margin-left: 20px;
      margin-top: 0;
    }
  }

  ${IS_MOBILE} {
    align-items: center;
    padding-top: 70px;
    height: 100%;

    > li:not(:first-child) {
      margin-top: 40px;
    }
  }
`;

const navLink = css`
  padding: 4px 8px;
  text-decoration: none;
  color: var(--text);
  border-radius: 24px;
  border: 3px solid transparent;
  transition: background-color 300ms, border-color 300ms, color 300ms;

  &:hover:not(.active) {
    border-color: rgb(255, 126, 12, 0.8);
    background-color: rgb(255, 126, 12, 0.3);
  }

  &:active {
    border-color: rgb(255, 126, 12, 0.6);
    background-color: rgb(255, 126, 12, 0.18);
    transform: scale(0.8);
  }

  &.active {
    color: rgba(255, 255, 255, 0.95);
    background-color: rgb(255, 126, 12);
    border-color: rgb(255, 126, 12);
  }
`;

const signOut = css`
  margin-left: 20px;
  padding: 0 8px;
  cursor: pointer;
`;

const NavItem = ({
  to,
  label,
  onClick,
}: {
  to: LinkProps["to"];
  label: React.ReactElement | string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) => {
  return (
    <li>
      <NavLink to={to} className={navLink} onClick={onClick}>
        <Text as="span" weight={600}>
          {label}
        </Text>
      </NavLink>
    </li>
  );
};

const NavMenu = ({
  onNavigateDismiss,
}: {
  onNavigateDismiss?: MouseEventHandler<HTMLAnchorElement>;
}) => {
  const user = firebaseAuth.currentUser;
  const navigate = useNavigate();

  const handleSignOut = useCallback(() => {
    firebaseAuth.signOut();
    navigate("/");
  }, [navigate]);

  return (
    <ul className={navLinks}>
      <NavItem to="/" label="home" onClick={onNavigateDismiss} />
      <NavItem to="/events" label="events" onClick={onNavigateDismiss} />
      <NavItem to="/info" label="info" onClick={onNavigateDismiss} />
      <NavItem to="/entourage" label="entourage" onClick={onNavigateDismiss} />
      {user && (
        <li className={signOut} onClick={handleSignOut}>
          <Logout color="icon-neutral" a11yTitle="sign out" />
        </li>
      )}
    </ul>
  );
};

const navControlButton = css`
  padding: 8px;
`;

const closeButton = css`
  position: fixed;
  top: 20px;
  right: ${MOBILE_GUTTER}px;
`;

const MobileNavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);
  const location = useLocation();
  const pageTitleMatch = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
  const pageTitle = pageTitleMatch ? titleCase(pageTitleMatch) : "";

  return (
    <>
      <Heading size="small" id="page-title">
        {pageTitle.toLowerCase()}
      </Heading>
      <button type="button" onClick={openMenu} className={navControlButton}>
        <HamburgerIcon color="icon-neutral" onClick={openMenu} />
      </button>
      {isOpen && (
        <Layer animation="fadeIn">
          <Theme theme={lightTheme} themeMode="light">
            <button type="button" className={cx(closeButton, navControlButton)} onClick={closeMenu}>
              <Close color="icon-neutral" />
            </button>
            <NavMenu onNavigateDismiss={closeMenu} />
          </Theme>
        </Layer>
      )}
    </>
  );
};

const webNav = css`
  opacity: 0;
`;

const variants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: { opacity: 0, y: -15 },
};

const transition: Transition = {
  duration: 0.06,
  easings: "easeOut",
};

const WebStickyHeader = () => {
  const controls = useAnimation();
  const scrollYRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const isScrollingUp = window.scrollY < scrollYRef.current;
      if (window.scrollY < 30 && isScrollingUp) {
        controls.start("visible");
      } else {
        controls.start("hidden");
      }
      scrollYRef.current = window.scrollY;
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  const location = useLocation();

  useEffect(() => {
    if (window.scrollY === 0) {
      controls.set("visible");
    } else {
      controls.set("hidden");
    }
  }, [controls, location]);

  return (
    <motion.nav
      initial="visible"
      className={webNav}
      animate={controls}
      variants={variants}
      transition={transition}
    >
      <NavMenu />
    </motion.nav>
  );
};

const navHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px ${MOBILE_GUTTER}px;
  z-index: 1;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  ${IS_DESKTOP} {
    left: unset;
    padding: 60px ${DESKTOP_HORIZONTAL_MARGIN}px 0;
    justify-content: flex-end;
  }
`;

export const Nav = memo(function Nav() {
  const isMobile = useMediaQuery(MOBILE_QUERY);

  const location = useLocation();
  const isDarkRoute = darkRoutes.includes(location.pathname);

  const { scrollY } = useScroll();
  const backgroundColor = useTransform(
    scrollY,
    [5, 25],
    ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 1)"]
  );

  return (
    <Theme themeMode={isDarkRoute ? "dark" : "light"}>
      <motion.header
        id="nav-bar"
        className={navHeader}
        style={{
          backgroundColor: !isMobile || isDarkRoute ? "transparent" : backgroundColor,
        }}
      >
        {isMobile ? <MobileNavMenu /> : <WebStickyHeader />}
      </motion.header>
    </Theme>
  );
});
