import React from "react";

import { css } from "@linaria/core";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

export const globals = css`
  :global() {
    /* @font-face {
      font-family: "Maison Mono";
      src: url("//db.onlinewebfonts.com/t/2b8e4e5a8843929a995f2456a8ac413f.woff2") format("woff2");
      font-display: swap;
      font-weight: 500;
    }

    @font-face {
      font-family: "Maison Mono";
      src: url("//db.onlinewebfonts.com/t/c385150224fb181d73545d7513a68528.woff2") format("woff2");
      font-display: swap;
      font-weight: 400;
    } */

    @font-face {
      font-family: "Maison Neue";
      src: url("./fonts/Maison Neue/MaisonNeueLight.woff2") format("woff2");
      font-display: swap;
      font-weight: 300;
    }

    @font-face {
      font-family: "Maison Neue";
      src: url("./fonts/Maison Neue/MaisonNeueBook.woff2") format("woff2");
      font-display: swap;
      font-weight: 400;
    }

    @font-face {
      font-family: "Maison Neue";
      src: url("./fonts/Maison Neue/MaisonNeueMedium.woff2") format("woff2");
      font-display: swap;
      font-weight: 500;
    }

    @font-face {
      font-family: "Maison Neue";
      src: url("./fonts/Maison Neue/MaisonNeueDemi.woff2") format("woff2");
      font-display: swap;
      font-weight: 600;
    }

    @font-face {
      font-family: "Maison Neue Mono";
      src: url("./fonts/Maison Neue/MaisonNeueMonoRegular.woff2") format("woff2");
      font-display: swap;
      font-weight: 400;
    }

    @font-face {
      font-family: "Maison Neue Mono";
      src: url("./fonts/Maison Neue/MaisonNeueMonoBold.woff2") format("woff2");
      font-display: swap;
      font-weight: 600;
    }

    html {
      box-sizing: border-box;
    }

    body {
      font-family: "Maison Neue", "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
      text-rendering: optimizeSpeed;
    }

    #root {
      position: relative;
      min-height: 100vh;
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
    }

    ol,
    ul {
      margin: 5px 20px;
      padding: 10px;
      list-style: square;
    }

    h2 {
      margin: 30px 0 16px;
    }

    h3 {
      margin: 16px 0 10px;
    }

    p {
      margin-bottom: 8px;
    }

    em {
      font-style: normal;
      font-weight: 600;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .mono {
      font-family: "Maison Neue Mono", "Courier New", Courier, monospace;
      font-weight: 400;
    }

    .semibold {
      font-weight: 500;
    }

    .bold {
      font-weight: 600;
    }

    .underline {
      text-decoration: underline;
    }

    /* fix airbnb react-dates week header misalignment */
    div.DayPicker_weekHeader {
      top: 36px;
    }

    input.DateInput_input {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
